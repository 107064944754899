html {
  height: 100%;
  width: 100vw;
}

body {
  overflow-x: hidden;
  padding-right: 0 !important;
  height: 100%;
}

ul {
  padding-left: 16px;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
